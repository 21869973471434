var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex t-0 l-0 po-a transparent box-border",
      style: _vm.renderStyle,
    },
    [
      _vm.data.orderChannelIcon
        ? _c(
            "div",
            {
              staticClass: "flex-center",
              style: {
                flex: `0 0 ${_vm.scaleWidth}`,
                height: _vm.height + "px",
                width: _vm.scaleWidth,
              },
            },
            [
              _c("img", {
                staticClass: "w-100",
                style: _vm.transform,
                attrs: { src: _vm.icon },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "text-nowrap h-100 flex",
          style: { ..._vm.specialStyle, ..._vm.flexStyle },
        },
        [_vm._v(" 0000 ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }