<!--
 * @Author: 伽蓝
 * @Date: 2020-08-12 11:06:38
 * @LastEditTime: 2021-01-14 10:43:13
 * @LastEditors: 伽蓝
 * @Description: 叫号板组件
 * @FilePath: /h5-web-view/src/components/mod/call-board/2.1.0.vue
 * @代码不规范,调试两行泪
-->
<template>
  <div class="flex t-0 l-0 po-a transparent box-border" :style="renderStyle">
    <div v-if="data.orderChannelIcon" class="flex-center"
      :style="{flex:`0 0 ${scaleWidth}`,height:height+'px',width:scaleWidth}">
      <img class="w-100" :style="transform" :src="icon" />
    </div>
    <div class="text-nowrap h-100 flex" :style="{...specialStyle,...flexStyle}">
      0000
    </div>
  </div>
</template>

<script>
export default {
  name: 'call-board-2-1-0',
  props: {
    width: { type: Number },
    height: { type: Number },
    data: { type: Object }
  },
  computed: {
    // 间隔
    specialStyle () {
      return {
        paddingLeft: this.data.intervalValue + '%',
        backgroundColor: this.data.style.backgroundColor,
        flex: 1
      }
    },
    renderStyle () {
      const { height, width } = this
      const { fontFamily } = this.data
      const { fontSize, letterSpacing } = this.data.style
      return {
        ...this.data.style,
        fontFamily,
        height: height + 'px',
        width: width + 'px',
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px'
      }
    },
    flexStyle () {
      const { textAlign, alignItems } = this.data.style
      const justifyContent = this.$getJustifyContent(textAlign)
      return {
        justifyContent,
        alignItems
      }
    },
    icon () {
      const { orderChannelType, orderType } = this.data
      const index = orderChannelType.findIndex(item => { return item.value === orderType[0] })
      return orderChannelType[index].icon
    },
    scale () {
      return this.data.iconScale / 100
    },
    scaleWidth () {
      return Math.floor(this.scale * this.height) + 'px'
    },
    transform () {
      return `scale(${this.scale})`
    }

  },
  methods: {
    $getJustifyContent (textAlign) {
      let justifyContent = textAlign
      textAlign === 'right' && (justifyContent = 'flex-end')
      textAlign === 'left' && (justifyContent = 'flex-start')
      return justifyContent
    }
  }
}
</script>
<style lang="css">
.text-nowrap {
  white-space: nowrap;
  flex: 0 0 auto;
}
</style>
